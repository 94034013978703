<template>
    <el-dialog :title="title" :visible="visible" width="35%" @close="handleClose">
        <span v-loading="$waiting.is('updating')" class="w-full h-full block">
            <el-form ref="form" label-position="top" :model="form" :rules="rules" label-width="130px">
                <el-radio-group v-model="form.isInactive" class="mb-4">
                    <el-radio-button label="false">Aktiva</el-radio-button>
                    <el-radio-button label="true">Inaktiv</el-radio-button>
                </el-radio-group>
                <el-form-item label="Kategorigroup" prop="groupName">
                    <el-input v-model="form.groupName" disabled />
                </el-form-item>
                <el-form-item label="Typ" prop="type">
                    <el-select v-model="form.type" class="w-full" disabled>
                        <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" :selected="form.type === item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="Namn" prop="name">
                    <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item label="Engelska Namn" prop="nameEn">
                    <el-input v-model="form.nameEn" />
                </el-form-item>
                <el-form-item label="Kontoplan" prop="accountPlanNumber">
                    <el-select filterable v-model="form.accountPlanNumber" class="w-full">
                        <el-option v-for="item in accountPlans" :key="item.value" :label="item.name" :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="Moms" prop="vatId">
                    <el-select filterable v-model="form.vatId" class="w-full">
                        <el-option v-for="item in vatOptions" :key="item.value" :label="item.name" :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="Taggar">
                    <Tags @updateTags="updateTags" :dynamic-tags="selectedTags" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">Avbryt</el-button>
                <el-button type="primary" @click="onSave">Spara</el-button>
            </span>
        </span>
    </el-dialog>
</template>
<script>
import Api from "../../accountCategories.api";
import queryString from "query-string";
import { mapState } from "vuex";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        groupDetails: {
            type: Object,
        },

        categoryDetails: {
            type: Object,
        },
    },

    watch: {
        categoryDetails: {
            async handler() {
                if (Object.keys(this.categoryDetails).length !== 0) {
                    this.form = { ...this.categoryDetails };
                } else {
                    this.form = {
                        id: 0,
                        name: "",
                        nameEn: "",
                        accountPlanNumber: undefined,
                        vatId: undefined,
                        tags: [],
                    };
                }
                this.assignInit();
            },
        },

        groupDetails: {
            async handler() {
                this.assignInit();
            },
        },
    },

    data() {
        return {
            selectedTags: [],
            rules: {
                name: [{ required: true, message: "Namn saknas", trigger: "blur" }],
                nameEn: [{ required: true, message: "Namn engelska saknas", trigger: "blur" }],
                accountPlanNumber: [{ required: true, message: "Kontoplan saknas", trigger: "blur" }],
                vatId: [{ required: true, message: "Moms saknas", trigger: "blur" }],
            },
            form: {
                id: 0,
                name: "",
                nameEn: "",
                groupName: "",
                groupId: 0,
                type: "",
                accountPlanNumber: undefined,
                vatId: undefined,
                tags: [],
                isInactive: false,
            },
            accountPlanItems: [],
            vatOptions: [],
            typeOptions: [
                {
                    value: 1,
                    label: "Enskildfirma",
                },
                {
                    value: 2,
                    label: "Aktiebolag  ",
                },
            ],
        };
    },

    components: {
        Tags: () => import(/* webpackChunkName: "Tags" */ "../Tags.vue"),
    },

    methods: {
        assignInit() {
            this.form.groupName = this.groupDetails.name;
            this.form.groupId = this.groupDetails.id;
            const { type } = queryString.parse(location.search);
            this.form.type = type || "Enskildfirma";
            this.selectedTags = this.form.tags;
            this.getVatOptions();
        },

        updateTags(tags) {
            this.selectedTags = tags;
            this.form.tags = this.selectedTags;
        },

        handleClose() {
            this.$emit("close");
        },

        async getAccountPlans(type) {
            this.$waiting.start("updating");
            this.accountPlanItems = await Api.getAccountPlans(type);
            this.$waiting.end("updating");
        },

        async getVatOptions() {
            this.$waiting.start("updating");
            this.vatOptions = await Api.getVatOptions();
            this.$waiting.end("updating");
        },

        async onSave() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$waiting.start("updating");
            await Api.updateAccountCategories(this.form);
            this.$notify.success({ title: "Utfört" });
            this.$emit("reloadList");
            this.$waiting.end("updating");
        },
    },

    computed: {
        ...mapState({
            accountsInfo: state => state.supplierInvoice.accountsInfo,
        }),

        title() {
            return this.form.groupId === 0 ? "Skapa kategori" : this.form.groupName;
        },

        accountPlans() {
            if (this.form.type == "Enskildfirma") {
                return this.accountsInfo.filter(a => a.companyType == 1)[0].accounts;
            } else {
                return this.accountsInfo.filter(a => a.companyType == 2)[0].accounts;
            }
        },
    },
};
</script>
